import { useTranslation } from "react-i18next";
import styles from "./job_posts.css";
import { Body, SecondaryBody, SectionHeader } from "./typography";
import Table, { links as tableLinks, type Cell } from "./table";
import { type BoardJobPost } from "~/types/jben/board";
import { buildJobPostTags } from "~/utils/job_helpers";
import { useHiringTeamRenderer } from "~/hooks/use_hiring_team_renderer";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { useTrackingParams } from "~/hooks/use_tracking_params";

type Department = {
  id: number | undefined;
  name: string;
  path: string | undefined;
  jobPosts: Array<BoardJobPost>;
};

export const links = () => [...tableLinks(), { rel: "stylesheet", href: styles }];

const JobPosts = ({
  jobPosts = [],
  internalBoard = false,
  filtered




}: {jobPosts: Array<BoardJobPost>;internalBoard?: boolean;filtered?: boolean;}) => {
  let { t } = useTranslation("board");
  const { display_hiring_team, display_department_hierarchy } = useBoardConfiguration();
  const { buildRecruiterText, buildHiringManagerText } = useHiringTeamRenderer();
  const { withTrackingParams } = useTrackingParams();
  const shouldDisplayHiringTeam = display_hiring_team && internalBoard;

  if (jobPosts.length === 0) {
    return (
      <div className="job-posts__empty-state">
        {filtered ?
        <>
            <Body metadata>{t("job_posts.no_jobs_matching_filter")}</Body>
            <SecondaryBody metadata>{t("job_posts.change_filters")}</SecondaryBody>
          </> :

        <Body metadata>{t("job_posts.no_job_posts")}</Body>}

      </div>);

  }

  const departments: Department[] = [];
  jobPosts.forEach((jobPost) => {
    const postUnder = jobPost.department || jobPost.section;
    const department = departments.find((department) => department.id === postUnder?.id);

    if (department) {
      department.jobPosts.push(jobPost);
    } else {
      departments.push({
        id: jobPost.department?.id || jobPost.section?.id,
        name: jobPost.department?.name || jobPost.section?.name || t("no_department"),
        path: jobPost.department?.path.join(" / "),
        jobPosts: [jobPost]
      });
    }
  });

  const renderDepartment = (department: Department) => {
    const key = department.id || -1;

    const headers = [];
    // Only include headers if there will be > 1 column
    if (shouldDisplayHiringTeam) {
      headers.push(t("job_posts.job_header"));
      headers.push(t("job_posts.contacts_header"));
    }

    const rows = department.jobPosts.map((jobPost) => {
      const cells: Cell[] = [
      {
        primaryBody: jobPost.title,
        emphasis: true,
        secondaryBody: jobPost.location,
        href: withTrackingParams(jobPost.absolute_url),
        tags: buildJobPostTags(jobPost)
      }];


      if (shouldDisplayHiringTeam) {
        const hiringManagerText = buildHiringManagerText(
          jobPost.hiring_team_members?.hiring_managers || []
        );
        const recruiterText = buildRecruiterText(jobPost.hiring_team_members?.recruiters || []);

        cells.push({
          primaryBody: hiringManagerText || "",
          secondaryBody: recruiterText || "",
          href: withTrackingParams(jobPost.absolute_url)
        });
      }

      return cells;
    });

    return (
      <div className="job-posts" key={key}>
        {display_department_hierarchy && department.path &&
        <div className="job-posts--department-path">
            <SecondaryBody metadata>{department.path}</SecondaryBody>
          </div>}

        <SectionHeader>{department.name}</SectionHeader>
        <div className="job-posts--table">
          <Table
            rows={rows}
            headers={headers}
            rowClassName="job-post"
            defaultHeader={t("job_posts.job_header")} />

        </div>
      </div>);

  };

  return <div>{departments.map((department) => renderDepartment(department))}</div>;
};

export default JobPosts;