import classNames from "classnames";
import PreviousIcon from "../icons/previous";
import NextIcon from "../icons/next";
import { paginate } from "../../utils/pagination";
import styles from "./index.css";
import { useLocation, useSubmit } from "@remix-run/react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange?: (arg1: number) => void;
  center?: boolean;
}

export const links = () => [{ rel: "stylesheet", href: styles }];

const Pagination = (props: PaginationProps) => {
  const { currentPage = 1, totalPages, onPageChange, center } = props;
  const location = useLocation();
  const submit = useSubmit();

  const updatePageParam = (page: string | number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("page");
    searchParams.append("page", page.toString());
    submit(searchParams);
  };

  const classes = (name: string, value?: number | string) => {
    switch (name) {
      case "pagination":
        return classNames({
          pagination: true,
          "pagination--center": center
        });
      case "pagination__link":
        return classNames({
          pagination__link: true,
          "pagination__link--active": currentPage === value
        });
      default:
        return "";
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      if (onPageChange) {
        onPageChange(currentPage - 1);
      }
      updatePageParam(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      if (onPageChange) {
        onPageChange(currentPage + 1);
      }
      updatePageParam(currentPage + 1);
    }
  };

  const renderPaginationButton = (type: string) => {
    const previous = type === "previous";
    const firstPage = currentPage === 1;
    const lastPage = currentPage === totalPages;
    const inactive = previous ? firstPage : lastPage;
    const ariaLabel = previous ? "Previous page" : "Next page";
    const onClick = previous ? previousPage : nextPage;

    const className = classNames({
      pagination__btn: true,
      [`pagination__${type}`]: true,
      [`pagination__${type}--inactive`]: inactive
    });

    return (
      <button
        aria-label={ariaLabel}
        aria-disabled={inactive}
        type="button"
        className={className}
        onClick={onClick}>

        {previous ? <PreviousIcon viewBox="0 0 7 12" /> : <NextIcon viewBox="0 0 7 12" />}
      </button>);

  };

  const ellipsis = "...";

  const paginationLinks = paginate(currentPage, totalPages);

  const links =
  <ul>
      {paginationLinks.map((item: number | string, index: number) =>
    <li key={index}>
          {item === ellipsis ?
      <span>{item}</span> :

      <button
        aria-label={`Go to page ${item}`}
        aria-current={currentPage === item}
        className={classes("pagination__link", item)}
        onClick={() => updatePageParam(item)}>

              {item}
            </button>}

        </li>
    )}
    </ul>;


  return (
    <nav role="navigation" aria-label="Pagination" className={classes("pagination")}>
      {renderPaginationButton("previous")}
      {links}
      {renderPaginationButton("next")}
    </nav>);

};

export default Pagination;