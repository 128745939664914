export const range = (start: number, end: number) =>
  Array.from(new Array(end - start), (_, i) => i + start);

export const paginate = (currentPage: number, totalPages: number) => {
  const maxVisiblePages = 5;
  const ellipsis = "...";

  if (totalPages <= maxVisiblePages) {
    return range(1, totalPages + 1);
  }

  if (currentPage < maxVisiblePages) {
    return [...range(1, maxVisiblePages), ellipsis, totalPages];
  }

  if (currentPage > totalPages - maxVisiblePages + 1) {
    return [1, ellipsis, ...range(totalPages - 3, totalPages + 1)];
  }

  return [1, ellipsis, ...range(currentPage - 1, currentPage + 2), ellipsis, totalPages];
};
