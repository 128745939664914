import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const JobIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        d="M21.6001 5.66513H16.0669C16.0669 5.64593 16.0669 5.62793 16.0669 5.60993C16.0669 4.53071 15.6381 3.49569 14.875 2.73257C14.1119 1.96944 13.0769 1.54073 11.9977 1.54073C10.9184 1.54073 9.88342 1.96944 9.12029 2.73257C8.35717 3.49569 7.92845 4.53071 7.92845 5.60993C7.92845 5.62793 7.92845 5.64593 7.92845 5.66513H2.40005C2.2091 5.66513 2.02596 5.74098 1.89094 5.87601C1.75591 6.01103 1.68005 6.19417 1.68005 6.38513V21.3851C1.68005 21.5761 1.75591 21.7592 1.89094 21.8942C2.02596 22.0293 2.2091 22.1051 2.40005 22.1051H21.6001C21.791 22.1051 21.9741 22.0293 22.1092 21.8942C22.2442 21.7592 22.3201 21.5761 22.3201 21.3851V6.38513C22.3201 6.19417 22.2442 6.01103 22.1092 5.87601C21.9741 5.74098 21.791 5.66513 21.6001 5.66513ZM9.37085 5.60993C9.37085 4.91262 9.64786 4.24387 10.1409 3.7508C10.634 3.25773 11.3027 2.98073 12.0001 2.98073C12.6974 2.98073 13.3661 3.25773 13.8592 3.7508C14.3522 4.24387 14.6293 4.91262 14.6293 5.60993C14.6293 5.62793 14.6293 5.64593 14.6293 5.66513H9.36845C9.36965 5.64593 9.37085 5.62793 9.37085 5.60993ZM20.8801 7.10512V12.2939H12.7201V11.5739C12.7201 11.4794 12.7014 11.3857 12.6652 11.2984C12.6291 11.211 12.576 11.1317 12.5092 11.0648C12.4423 10.9979 12.3629 10.9449 12.2756 10.9087C12.1882 10.8725 12.0946 10.8539 12.0001 10.8539C11.9055 10.8539 11.8119 10.8725 11.7245 10.9087C11.6372 10.9449 11.5578 10.9979 11.4909 11.0648C11.4241 11.1317 11.371 11.211 11.3349 11.2984C11.2987 11.3857 11.2801 11.4794 11.2801 11.5739V12.2939H3.12005V7.10512H20.8801ZM3.12005 20.6651V13.7339H11.2801V14.4539C11.2801 14.6449 11.3559 14.828 11.4909 14.963C11.626 15.0981 11.8091 15.1739 12.0001 15.1739C12.191 15.1739 12.3741 15.0981 12.5092 14.963C12.6442 14.828 12.7201 14.6449 12.7201 14.4539V13.7339H20.8801V20.6651H3.12005Z"
      />
    </IconSvgWrapper>
  );
};

export default JobIcon;
