import { PageHeader } from "./typography";
import { withNamespace } from "~/utils/translation";

interface HeaderProps {
  headerText: string | null | undefined;
  companyName: string;
}

export const Header = ({ headerText, companyName }: HeaderProps) => {
  const t = withNamespace("board");

  if (headerText?.trim() === "") {
    return null;
  }

  const boardHeader = headerText || t("current_openings", { companyName });

  return <PageHeader>{boardHeader}</PageHeader>;
};
