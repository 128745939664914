import { BodyDiv } from "~/components/typography";
import LocationIcon from "~/components/icons/location";
import JobIcon from "~/components/icons/job";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import type { FeaturedBoardJobPost } from "~/types/jben/board";

interface Props {
  featuredPost: FeaturedBoardJobPost;
}

const FeaturedPost = ({ featuredPost }: Props) => {
  const { withTrackingParams } = useTrackingParams();

  return (
    <a href={withTrackingParams(featuredPost.absolute_url)}>
      <div className={"featured-posts__card"}>
        <p className={"body body--medium featured-posts__title"}>{featuredPost.title}</p>
        <div className={"featured-posts__tags"}>
          {featuredPost.location && (
            <div className={"featured-posts__tag"}>
              <LocationIcon size="md" />
              <p className={"body body__secondary body--metadata featured-posts__tag-text"}>
                {featuredPost.location}
              </p>
            </div>
          )}
          {featuredPost.payRange && (
            <div className={"featured-posts__tag"}>
              <JobIcon size="md" />
              <p className={"body body__secondary body--metadata featured-posts__tag-text"}>
                {featuredPost.payRange}
              </p>
            </div>
          )}
        </div>
        <div className={"featured-posts__content"}>
          <BodyDiv>
            <div dangerouslySetInnerHTML={{ __html: featuredPost.content || "" }}></div>
          </BodyDiv>
        </div>
      </div>
    </a>
  );
};

export default FeaturedPost;
